import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getAssetIds, getAssets} from "./assets-duck.js";

export const NAME = 'history';

let _history = {}
// let _curatedHistory = {}

const initialState = {
    history: {},
    date: undefined,
}

const {actions, reducer} = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setHistory: (state, {payload: history}) => {
            _history = {
                ...history
            }
            state.date = new Date()
        },
        reset: () => ({
            ...initialState
        }),
    }
})

export const {
    setHistory,
} = actions

const getState = state => state[NAME]

export const getHistory = () => _history

export const getHistoryArray2 = state => Object.entries(getHistory(state))
export const getHistoryArray = createSelector([getHistory], (history) => {
    return Object.entries(history)
})
export const getIsHistoryFullyLoaded = createSelector([getHistoryArray], historyArray => historyArray.length > 10)

export const getHistoryDate = state => getState(state).date
export const getRateHistoryForAsset = (state, assetId) =>
    getHistoryArray(state).map(item => ({
        date: item[0],
        rate: item[1][assetId]?.rate || 0,
    }))

export const getRateFromDate = (state, assetId, date) => {
    const assets = getHistory(state)[date]
    if (!assets) {
        return 0
    }
    return assets[assetId]?.rate || 0
}

export const getHistoryDateForAsset = (state, assetId, year) => {
    const historyArray = getHistoryArray(state)
    const date = [...historyArray].reverse().find(entry => entry[0].substring(0, 4) === year.toString())
    if (!date) {
        return {}
    }
    const assets = getHistory(state)[date[0]]
    if (!assets) {
        return null
    }
    return {
        date: date[0],
        ...assets[assetId],
    }
}

export const getTaxReportAssets = (state, year) => {
    const assets = getAssets(state)
    return assets.map(asset => ({
        ...asset,
        ...getHistoryDateForAsset(state, asset.id, year)
    }))
}

export const getLatestDateWithChange = createSelector([getHistoryArray, getAssetIds], (historyArray, assetIds) => {
    let date
    for (let i = historyArray.length - 1; i > 0; i--) {
        const [currentDate, data] = historyArray[i]
        for (const assetId of assetIds) {
            if (data[assetId]?.gainChange !== 0) {
                date = currentDate
                i = 0
                break
            }
        }
    }
    return date
})

export default reducer