import './AssetList.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    FILTER_KEY_ALL,
    getAssets,
    getComparativeAssetCode,
    getFilterPreset,
    getIsAllAssetListGrouped,
    getIsAssetListCompositionBarVisible,
    getSortIsAsc,
    getSortProp,
    setIsAllAssetListGrouped,
    setSortIsAsc,
    setSortProp
} from "../../ducks/assets-duck.js";
import {APP_ROUTES} from "../../App.jsx";
import Table from "../util/table/Table.jsx";
import ThSortToggler from "../util/table/ThSortToggler.jsx";
import {getUpdateKey} from "../../ducks/chart-duck.js";
import InternalLink from "../util/navigation/InternalLink.jsx";
import PlusIcon from "mdi-react/PlusIcon.js";
import orderBy from "lodash/orderBy.js";
import AssetListCompositionBar from "./AssetListCompositionBar.jsx";
import AssetRow from "./AssetRow.jsx";
import {groupAssetsByType} from "./_helpers.js";
import Toggle from "../util/Toggle.jsx";

const TH = ({children, sortProp, sortProps, className, isNumeric}) => {

    const currentSortProp = useSelector(getSortProp)
    const currentSortIsAsc = useSelector(getSortIsAsc)

    return (
        <ThSortToggler
            className={className}
            sortProp={sortProp}
            sortProps={sortProps}
            currentSortProp={currentSortProp}
            currentSortIsAsc={currentSortIsAsc}
            toggleSortAction={setSortProp}
            toggleIsAscAction={setSortIsAsc}
            isNumeric={isNumeric}
        >
            {children}
        </ThSortToggler>
    )
}

export default () => {

    const dispatch = useDispatch()

    const assets = useSelector(getAssets)
    const filterPreset = useSelector(getFilterPreset)
    const comparativeAssetCode = useSelector(getComparativeAssetCode)
    const updateKey = useSelector(getUpdateKey)
    const currentSortProp = useSelector(getSortProp)
    const currentSortIsAsc = useSelector(getSortIsAsc)
    const isAssetListCompositionBarVisible = useSelector(getIsAssetListCompositionBarVisible)
    const isAllAssetListGrouped = useSelector(getIsAllAssetListGrouped)

    const isAllAssetList = filterPreset === FILTER_KEY_ALL

    const assetsGrouped = isAllAssetList && isAllAssetListGrouped ? groupAssetsByType(assets) : assets

    const assetsOrdered = orderBy(
        assetsGrouped,
        ['isMostRecentUpdate', currentSortProp, 'value'],
        ['desc', currentSortIsAsc ? 'asc' : 'desc', 'desc']
    )

    return (
        <div className='asset-list' key={updateKey}>
            {isAllAssetList &&
            <div className='group-assets-toggle children-slide-right'>
                <Toggle
                    isChecked={isAllAssetListGrouped}
                    onChange={value => dispatch(setIsAllAssetListGrouped(value))}
                >
                    group
                </Toggle>
            </div>
            }
            {assets.length > 0 &&
            <>
                {isAssetListCompositionBarVisible && <AssetListCompositionBar/>}
                <Table>
                    <thead>
                    <tr>
                        <th className='hide-portrait'/>
                        <TH
                            sortProps={['name']}
                        >
                            {isAllAssetListGrouped ? 'Group' : 'Name'}
                        </TH>
                        <TH
                            isNumeric={true}
                            className='hide-portrait'
                            sortProps={['currentRate']}
                        >
                            {isAllAssetListGrouped ? '' : 'Rate'}
                        </TH>
                        <th className='numeric hide-portrait'>
                            {comparativeAssetCode}
                        </th>
                        <TH
                            isNumeric={true}
                            className='hide-portrait'
                            sortProps={['value']}
                        >
                            Value
                        </TH>
                        <TH
                            isNumeric={true}
                            className='hide-portrait'
                            sortProps={['gain', 'gainPct']}
                        >
                            Return
                        </TH>
                        <TH
                            isNumeric={true}
                            sortProps={['change', 'changePct', 'changePctAbs']}
                        >
                            Change
                        </TH>
                        <th className='hide-portrait'/>
                    </tr>
                    </thead>
                    <tbody className='children-slide-right'>
                    {assetsOrdered.map(asset =>
                        <AssetRow
                            key={asset.id}
                            {...asset}
                        />
                    )}
                    </tbody>
                    {assets.length > 0 &&
                    <tfoot>
                    <tr className='hide-portrait'>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td className='numeric dull'>
                        </td>
                        <td className='numeric'>
                        </td>
                        <td className='numeric'>
                        </td>
                        <td className='numeric'>
                        </td>
                        <td>
                        </td>
                    </tr>
                    </tfoot>
                    }
                </Table>
            </>
            }
            {assets.length === 0 &&
            <div className='options-bar'>
                <InternalLink
                    to={APP_ROUTES.transactionsAdd}
                    isButton={true}
                    icon={<PlusIcon/>}
                >
                    add asset
                </InternalLink>
            </div>
            }
        </div>
    )
}