import {isMobile} from "../../util/misc.js";

export const getOptions = ({
                               isZeroBaseValue = false,
                               onMouseLeave = () => {
                               },
                               onMouseEnter = () => {
                               },
                               onMouseMove = dataIndex => console.log(dataIndex),
                               showDates = !isMobile(),
                           }) => ({
    // maintainAspectRatio: false,
    interaction: {
        mode: 'index',
        axis: 'x',
        intersect: false,
    },
    elements: {
        line: {
            tension: 1
        },
    },
    events: ['mouseenter', 'mouseleave', 'mouseout', 'mousedown', 'mouseup', 'mousemove'],
    plugins: {
        eventPlugin: {
            events: ['mouseenter', 'mouseleave'],
            onMouseLeave,
            onMouseEnter,
        },
        tooltip: {
            enabled: false,
            external: function (context) {
                const {dataIndex} = context.tooltip?.dataPoints[0]
                onMouseMove(dataIndex)
            }
        },
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                round: 'date',
                displayFormats: {
                    month: 'MMMM YYYY',
                    // hour: 'MM-DD HH:ii'
                },
            },
            border: {
                display: showDates,
            },
            ticks: {
                display: showDates,
            },
            grid: {
                display: false,
            },
        },
        y: {
            beginAtZero: isZeroBaseValue,
            border: {
                display: !isMobile(),
            },
            ticks: {
                display: !isMobile(),
            },
            grid: {
                display: false,
            },
        }
    }
})