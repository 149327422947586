import './Settings.scss'
import Button from "../util/Button.jsx";
import {useDispatch, useSelector} from "react-redux";
import {getIsOddy, logout} from "../../ducks/user-duck.js";
import Toggle from "../util/Toggle.jsx";
import {
    getIsAthGolden,
    getIsZeroBaseValue,
    getShowLatestValue,
    setIsAthGolden,
    setIsZeroBaseValue,
    setShowLatestValue
} from "../../ducks/chart-duck.js";
import AssetSelector from "../util/asset-selector/AssetSelector.jsx";
import {
    FILTER_KEY_CRYPTO,
    getComparativeAssetId,
    getDustThreshold,
    getIsAssetListCompositionBarVisible,
    getIsDustFiltered,
    setComparativeAssetId,
    setDustThreshold,
    setIsAssetListCompositionBarVisible,
    setIsDustFiltered
} from "../../ducks/assets-duck.js";
import PowerIcon from "mdi-react/PowerIcon.js";
import InternalLink from "../util/navigation/InternalLink.jsx";
import {APP_ROUTES} from "../../App.jsx";
import PlusIcon from "mdi-react/PlusIcon.js";
import {closePopup} from "../../ducks/popup-duck.js";
import PlusBoxMultipleOutlineIcon from "mdi-react/PlusBoxMultipleOutlineIcon.js";
import BankOutlineIcon from "mdi-react/BankOutlineIcon.js";
import {DECIMAL_FORMAT, numberFormat} from "../../util/numbers.js";
import WalletOutlineIcon from "mdi-react/WalletOutlineIcon.js";

const DUST_THRESHOLDS = [10, 1_000, 5_000, 10_000, 50_000, 100_000]

export default () => {

    const dispatch = useDispatch()
    const showLatestValue = useSelector(getShowLatestValue)
    const isZeroBaseValue = useSelector(getIsZeroBaseValue)
    const isAthGolden = useSelector(getIsAthGolden)
    const comparativeAssetId = useSelector(getComparativeAssetId)
    const isDustFiltered = useSelector(getIsDustFiltered)
    const isAssetListCompositionBarVisible = useSelector(getIsAssetListCompositionBarVisible)
    const dustThreshold = useSelector(getDustThreshold)
    const isOddy = useSelector(getIsOddy)

    const onClickClosePopup = () => dispatch(closePopup())

    const onClickLogout = () => {
        dispatch(logout())
        onClickClosePopup()
    }

    return (
        <div className='settings'>
            <h2>
                Settings
            </h2>
            <h3>
                Chart
            </h3>
            <div className='toggle-container'>
                <Toggle
                    isChecked={showLatestValue}
                    onChange={value => dispatch(setShowLatestValue(value))}
                >
                    Show line for latest value
                </Toggle>
            </div>
            <div className='toggle-container'>
                <Toggle
                    isChecked={isAthGolden}
                    onChange={value => dispatch(setIsAthGolden(value))}
                >
                    Golden color when ATH
                </Toggle>
            </div>
            <div className='toggle-container'>
                <Toggle
                    isChecked={isZeroBaseValue}
                    onChange={value => dispatch(setIsZeroBaseValue(value))}
                >
                    Zero base value
                </Toggle>
            </div>
            <h3>Assets</h3>
            <div className='toggle-container'>
                <Toggle
                    isChecked={isAssetListCompositionBarVisible}
                    onChange={() => dispatch(setIsAssetListCompositionBarVisible(!isAssetListCompositionBarVisible))}
                >
                    Asset list change visualization
                </Toggle>
            </div>
            <div className='toggle-container flex-vertical-center dust'>
                <Toggle
                    isChecked={isDustFiltered}
                    onChange={() => dispatch(setIsDustFiltered(!isDustFiltered))}
                >
                    Filter out dust assets <span className='weak'> value &lt; kr {numberFormat(dustThreshold)}</span>
                </Toggle>
                {isDustFiltered &&
                <div className='flex-vertical-center hide-portrait dust-thresholds'>
                    {DUST_THRESHOLDS.map(threshold =>
                        <Button key={threshold} isChecked={dustThreshold === threshold}
                                onClick={() => dispatch(setDustThreshold(threshold))}>
                            {numberFormat(threshold, DECIMAL_FORMAT.FLEX_INT)}
                        </Button>
                    )}
                </div>
                }
            </div>
            <AssetSelector
                headerSelected={'Comparative base asset'}
                selectedAssetId={comparativeAssetId}
                selectAsset={assetId => dispatch(setComparativeAssetId(assetId))}
                isScrollable={true}
            />
            <h3>
                Transactions
            </h3>
            <div className='transaction-settings'>
                <InternalLink
                    to={APP_ROUTES.transactionsAddBulk}
                    isButton={true}
                    icon={<PlusBoxMultipleOutlineIcon/>}
                    onClick={onClickClosePopup}
                    className='bulk-link'
                >
                    bulk transactions
                </InternalLink>
                <InternalLink
                    to={APP_ROUTES.transactionsAdd}
                    isButton={true}
                    icon={<PlusIcon/>}
                    onClick={onClickClosePopup}
                >
                    add transaction
                </InternalLink>
            </div>
            <div className='flex-vertical-center footer'>
                <InternalLink
                    className='hide-portrait'
                    to={APP_ROUTES.taxReport}
                    isButton={true}
                    icon={<BankOutlineIcon className='std-icon'/>}
                    onClick={onClickClosePopup}
                >
                    tax report
                </InternalLink>
                {isOddy &&
                <InternalLink
                    to={APP_ROUTES.cryptoBalances}
                    isButton={true}
                    icon={<WalletOutlineIcon className='std-icon'/>}
                    onClick={onClickClosePopup}
                    params={{filterId: FILTER_KEY_CRYPTO}}
                >
                    accounts
                </InternalLink>
                }
                <Button
                    onClick={onClickLogout}
                    icon={<PowerIcon/>}
                >
                    Log out
                </Button>
            </div>
        </div>
    )
}