import {useSelector} from "react-redux";
import useLazyListLoader from "../../util/lasy-loader/useLazyListLoader.js";
import Table from "../../util/table/Table.jsx";
import Counter from "../../util/Counter.jsx";
import './AssetHistory.scss'
import {getHistoryArray} from "../../../ducks/history-duck.js";
import {getAssetIds, getCurrentAsset, getFilter} from "../../../ducks/assets-duck.js";
import {DECIMAL_FORMAT} from "../../../util/numbers.js";
import DirectionIcon from "../../util/icons/DirectionIcon.jsx";
import HorizontalLineIcon from "mdi-react/HorizontalLineIcon.js";
import {getDirectionColorClassName} from "../../../util/misc.js";
import ChangeFlavorIcon from "../../util/icons/ChangeFlavorIcon.jsx";
import LoaderHorizontal from "../../util/loaders/LoaderHorizontal.jsx";
import DateDiffHighlighter from "../../util/DateDiffHighlighter.jsx";
import dayjs from "dayjs";

export default () => {

    const history = useSelector(getHistoryArray)
    const filter = useSelector(getFilter)
    const {is_usd_asset} = useSelector(getCurrentAsset)

    const {visibleList, ListLoaderComponent} = useLazyListLoader([...history].reverse())

    if (history.length < 10) {
        return (
            <div className='asset-history loader-container'>
                <LoaderHorizontal/>
            </div>
        )
    }

    return (
        <div className='asset-history'>
            <Table>
                <thead>
                <tr>
                    <th className='hide-portrait'/>
                    <th>
                        Date
                    </th>
                    <th className='numeric'>
                        Rate
                    </th>
                    <th className='numeric hide-portrait'>
                        Value
                    </th>
                    <th className='numeric hide-portrait'>
                        Return
                    </th>
                    <th className='numeric'>
                        Change
                    </th>
                    <th className='hide-portrait'/>
                </tr>
                </thead>
                <tbody>
                {visibleList.map(date =>
                    <HistoryDate
                        key={`${date[0]}|${history.length}|${filter}`}
                        date={date[0]}
                        data={date[1]}
                        isUsdAsset={is_usd_asset}
                    />)
                }
                </tbody>
            </Table>
            <ListLoaderComponent/>
        </div>
    )
}

const HistoryDate = ({date, data, isUsdAsset}) => {
    const assetIds = useSelector(getAssetIds)
    const {
        value,
        valueYesterday,
        gain,
        gainChange,
        rate,
        usdRate,
    } = assetIds.reduce((acc, cur) => ({
        value: acc.value + data[cur].value,
        valueYesterday: acc.valueYesterday + data[cur].valueYesterday,
        gain: acc.gain + data[cur].gain,
        gainChange: acc.gainChange + data[cur].gainChange,
        rate: acc.rate + data[cur].rate,
        usdRate: acc.usdRate + data[cur].usdRate,
    }), {
        value: 0,
        valueYesterday: 0,
        gain: 0,
        gainChange: 0,
        rate: 0,
        usdRate: 0,
    })
    const gainChangePercent = gainChange / valueYesterday
    const changeClassName = getDirectionColorClassName(gainChangePercent, true)
    return (
        <tr>
            <td className='fade-left hide-portrait'/>
            <td className='date'>
                <div className='flex-vertical-center'>
                    <DateDiffHighlighter date={date}/> <ChangeFlavorIcon amount={gainChangePercent}/>
                </div>
                <div className='small weak'>
                    {dayjs(date).format('ddd')}
                </div>
            </td>
            <td className={`numeric rate`}>
                {assetIds.length > 1 ?
                    <HorizontalLineIcon className='no-value'/>
                    :
                    <Counter
                        amount={isUsdAsset ? usdRate : rate}
                        decimals={DECIMAL_FORMAT.FLEX_DEC}
                        prefix={isUsdAsset ? '$ ' : 'kr '}
                    />
                }

            </td>
            <td className={`numeric hide-portrait`}>
                <Counter
                    amount={value}
                />
            </td>
            <td className={`numeric hide-portrait`}>
                <Counter
                    className={getDirectionColorClassName(gain)}
                    amount={gain}
                    absolute={true}
                    decimals={DECIMAL_FORMAT.FLEX_DEC}
                />
            </td>
            <td className={`numeric `}>
                {gainChange === 0 ?
                    <>
                        <HorizontalLineIcon className='no-value'/>
                    </>
                    :
                    <>
                        <DirectionIcon value={gainChangePercent} isMultiColored={true}/>
                        <Counter
                            amount={gainChange}
                            absolute={true}
                        />
                        <div className={`small ${changeClassName}`}>
                            <Counter
                                amount={gainChangePercent * 100}
                                decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                                suffix='%'
                                absolute={true}
                            />
                        </div>
                    </>
                }
            </td>
            <td className='fade-right hide-portrait'/>
        </tr>
    )
}