import './AssetStats.scss'
import {useSelector} from "react-redux";
import {getHistoryArray} from "../../../ducks/history-duck.js";
import {
    getAssetIds,
    getAssetsTotalGain,
    getAssetsTotalValue,
    getCurrentAssetRate,
    getCurrentAssetRateDate, getIsSingleAsset
} from "../../../ducks/assets-duck.js";
import AllTimeHighBarometer from "./AllTimeHighBarometer.jsx";
import {getUpdateKey} from "../../../ducks/chart-duck.js";
import {getMaxValues} from "./_helpers.js";
import AssetKPIs from "./AssetKPIs.jsx";
import AssetUrls from "./AssetUrls.jsx";
import StatLine from "./StatLine.jsx";
import OffloadingGauge from "./OffloadingGauge.jsx";
import AssetCompositionBar from "./AssetCompositionBar.jsx";
import RateTrajectory from "./RateTrajectory.jsx";

export default () => {

    const history = useSelector(getHistoryArray)
    const assetIds = useSelector(getAssetIds)
    const isSingleAsset = useSelector(getIsSingleAsset)
    const updateKey = useSelector(getUpdateKey)

    const totalValue = useSelector(getAssetsTotalValue)
    const totalGain = useSelector(getAssetsTotalGain)

    const assetRate = useSelector(getCurrentAssetRate)
    const assetRateDate = useSelector(getCurrentAssetRateDate)

    const labels = history.map(date => date[0])
    const isRateCardVisible = !!assetRate

    const {
        maxValue,
        maxValueDate,
        maxGains,
        maxGainsDate,
        maxRate,
        maxRateDate,
    } = getMaxValues(history, labels, assetIds)

    return (
        <div className='asset-stats' key={updateKey}>
            <StatLine/>
            <AssetKPIs/>
            {isSingleAsset && <RateTrajectory />}
            <AssetUrls/>
            {isSingleAsset && <OffloadingGauge />}
            {!isSingleAsset && <AssetCompositionBar/>}
            <div className={`ath-barometers ${isRateCardVisible ? 'reduced-gap' : ''}`}>
                <AllTimeHighBarometer
                    currentValue={totalValue}
                    athValue={maxValue}
                    athDate={maxValueDate}
                >
                    Value ATH
                </AllTimeHighBarometer>
                <AllTimeHighBarometer
                    currentValue={totalGain}
                    initialValue={totalValue - maxGains}
                    athValue={maxGains}
                    athDate={maxGainsDate}
                >
                    {maxGains < 0 ? 'Loss' : 'Return'} ATH
                </AllTimeHighBarometer>
                {isRateCardVisible &&
                <AllTimeHighBarometer
                    currentValue={assetRate}
                    athValue={maxRate}
                    athDate={maxRateDate}
                    rateChange={maxRate - assetRate}
                    rateDate={assetRateDate}
                >
                    Price ATH
                </AllTimeHighBarometer>
                }
            </div>
        </div>
    )
}